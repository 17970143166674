import { FoxBizRequest, FoxRestBizClient } from "@/api/client";

/**
 * 포토폴리오 화면 관련 기능 클래스
 */
class Portfolio {

    /**
     * 생성자
     */
    constructor() {

    }

    /**
     * 포토폴리오 사업실적 목록 조회 메서트
     * @returns 
     */
    getBusinessPortfolioItems() {
        return new Promise((resolve, reject) => {
            
            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.SystemBiz';
            request.methodId = 'GetBusinessPortfolioItems';

            service.execute(request).then(response => {

                const rows = response.data.result.value.Table.rows;

                if (!!rows.length) resolve(rows);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 사업포트폴리오 기준분기 목록 조회 및 수주잔고 및 리츠 운영 자산 상세 조회
     * @param {Int} seq 시퀀스
     */
     getBusinessPortfolioAssets(seq) {
        return new Promise((resolve, reject) => {
            
            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.SystemBiz';
            request.methodId = 'GetBusinessPortfolioAssets';
            request.parameters.seq = seq;

            service.execute(request).then(response => {

                const tables = response.data.result.value;

                if (!!tables) resolve(tables);
                else resolve(null);
            }).catch(error => reject(error));
        });
    }

    /**
     * 포토폴리오 사업실적 저장
     * @param {Object} portfolioInfo 
     * @param {string} type Business: 사업실적, BaseQuarter: 기준분기,  (Orders: 수주잔고 , REITs: 리츠운영자산)동일한 메서드사용
     * @returns 
     */
    savePortfolioItems(portfolioInfo, type) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.SystemBiz';
            request.methodId = type === 'Business' ? 'UpdateBusinessPortfolioItems' 
                                    : type === 'BaseQuarter' ? 'MergeBusinessPortfolioBaseQuarter'
                                        : 'MergeBusinessPortfolioBaseQuarterDetail';
         
            request.parameters.portfoliInfo = JSON.stringify(portfolioInfo);

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     * 포토폴리오 사업실적 수정
     * @param {Object} portfolioInfo 
     * @param {string} type Business: 사업실적, BaseQuarter: 기준분기,  (Orders: 수주잔고 , REITs: 리츠운영자산)동일한 메서드사용
     * @returns 
     */
    updatePortfolioItems(portfolioInfo, type) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.SystemBiz';
            request.methodId = type === 'Business' ? 'UpdateBusinessPortfolioItems' 
                                    : type === 'BaseQuarter' ? 'MergeBusinessPortfolioBaseQuarter'
                                        : 'MergeBusinessPortfolioBaseQuarterDetail';
         
            request.parameters.portfoliInfo = JSON.stringify(portfolioInfo);

            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }

    /**
     * 포토폴리오 사업실적 삭제
     * @param {Object} portfolioInfo 
     * @param {string} type Business: 사업실적, BaseQuarter: 기준분기,  (Orders: 수주잔고 , REITs: 리츠운영자산)동일한 메서드사용
     * @returns 
     */
    deletePortfolioItems(portfolioInfo, type) {

        return new Promise((resolve, reject) => {

            let url = process.env.VUE_APP_BIZ_SERVICE_REST_API;
            const service = new FoxRestBizClient(url);
            const request = new FoxBizRequest();
            request.classId = 'Site.Admin.Biz.SystemBiz';
            request.methodId = type === 'Business' ? 'UpdateBusinessPortfolioItems' 
                                    : type === 'BaseQuarter' ? 'DeleteBusinessPortfolioBaseQuarter'
                                        : 'DeleteBusinessPortfolioBaseQuarterDetail';

            request.parameters.portfoliInfo = JSON.stringify(portfolioInfo);
            
            service.execute(request).then(response => {

                resolve(response);

            }).catch(error => reject(error));
        });
    }
}

export const portfolio = new Portfolio();