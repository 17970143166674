<template>
    <div class="admin-content">
        <h3>사업 포트폴리오</h3>

        <div class="tab-wrap1 col2 mb30">
            <ul>
                <li :class="state.tabIndex === 0 ? 'on' : ''"><a href="javascript:;" @click="state.tabIndex = 0" title="사업 실적">사업 실적</a></li>
                <li :class="state.tabIndex === 1 ? 'on' : ''"><a href="javascript:;" @click="state.tabIndex = 1" title="수주잔고 및 리츠 운영 자산">수주잔고 및 리츠 운영 자산</a></li>
            </ul>
        </div>
        <div v-if="state.tabIndex === 0">
            <div class="flex-wrap right">
                <div>
                    <div class="btn-wrap">
                        <button type="button" class="btn-box" @click="methods.getBusinessPortfolioItems">조회</button>
                        <button type="button" class="btn-box green" @click="eventHandler.portfolioRowAdd(state.portfolioBusinessItems)">추가</button>
                        <button type="button" class="btn-box red" @click="eventHandler.portfolioRowRemove(state.portfolioBusinessItems, state.type.business)">삭제</button>
                    </div>
                </div>
            </div>

            <div class="total-box">
                <p>Total <strong>{{state.portfolioBusinessItems === null ? 0 : state.portfolioBusinessItems.length}}</strong></p>
            </div>
            
            <DataTable class="p-datatable-sm"
                scrollHeight="497px"
                :value="state.portfolioBusinessItems"
                :resizableColumns="true"
                editMode="row"
                :rowHover="true"
                v-model:editingRows="state.portfolioBusinessRowDataModel"
                @row-edit-init="eventHandler.onRowEditInit($event, state.type.business)"
                @row-edit-save="eventHandler.onRowEditSave($event, state.portfolioBusinessItems, state.portfolioBusinessRowDataModel, state.type.business)"
                @row-edit-cancel="eventHandler.onRowEditCancel($event, state.portfolioBusinessItems)"
                >

                <template #empty>
                    데이터가 없습니다.
                </template>

                <Column field="Check" header="선택" :sortable="true" style="text-align:center; width:50px;" headerClass="tc">
                    <template #body = {data}>
                        <div class="chk-box">
                            <input type="checkbox" v-model="data.IsChecked" :binary="true" @change="eventHandler.onChange(data, 'Check')"/>
                            <label></label>
                        </div>
                    </template>
                </Column>
                <Column field="SortOrder" header="순번" :sortable="true" style="width:40px;" class="tc">
                    <template  #editor="{ data, field }">
                        <input class="input-text mr10" ref="sortOrder" v-model="data[ field ]"  />
                    </template>
                </Column>
                <Column field="BizContent" header="사업실적내용" :sortable="true" style="width:300px;" headerClass="tc">
                    <template  #editor="{ data, field }">
                        <input class="input-text mr10" v-model="data[ field ]"  />
                    </template>
                </Column>
                <Column field="BizBase" header="실적기준" :sortable="true" style="width:250px;" headerClass="tc">
                    <template  #editor="{ data, field }">
                        <input class="input-text mr10" v-model="data[ field ]"  />
                    </template>
                </Column>
                <Column field="BizResult" header="사업실적" :sortable="true" style="width:250px;" headerClass="tc">
                    <template  #editor="{ data, field }">
                        <input class="input-text mr10" v-model="data[ field ]"  />
                    </template>
                </Column>
                <Column field="DspYN" header="표시여부" :sortable="true" style="text-align:center; width:50px;" headerClass="tc">
                    <template #body = {data}>
                        <div class="chk-box">
                            <input type="checkbox" v-model="data.DspYN" :binary="true"/>
                            <label></label>
                        </div>
                    </template>
                </Column>
                <Column :rowEditor="true" style="width:100px;"></Column>
                <template #footer>
                    <p style="text-align:center;">사업실적 항목 입력시 단위까지 입력해주세요. ex) 2023년 | 1조 9,999억원 | 9,999억원</p>
                </template>
            </DataTable>
        </div>
        <div  v-else class="flex-wrap half mt20">
            <div class="col-7">
                
                <div class="flex-wrap space-between align-center mb10">
                    <div>
                        <p class="s-txt2">기준분기</p>
                    </div>
                    <div>
                        <div class="btn-wrap">
                            <button type="button" class="btn-box green" @click="eventHandler.portfolioRowAdd(state.portfolioBaseQuarterItems)">추가</button>
                            <button type="button" class="btn-box red" @click="eventHandler.portfolioRowRemove(state.portfolioBaseQuarterItems, state.type.baseQuarter)">삭제</button>
                        </div>
                    </div>
                </div>
                <!-- 기준분기 -->
                <DataTable class="p-datatable-sm"
                    scrollHeight="497px"
                    :value="state.portfolioBaseQuarterItems"
                    :resizableColumns="true"
                    editMode="row"
                    :rowHover="true"
                    v-model:editingRows="state.portfolioBaseQuarterRowDataModel"
                    @row-edit-init="eventHandler.onRowEditInit($event, state.type.baseQuarter)"
                    @row-edit-save="eventHandler.onRowEditSave($event, state.portfolioBaseQuarterItems, state.portfolioBaseQuarterRowDataModel, state.type.baseQuarter)"
                    @row-edit-cancel="eventHandler.onRowEditCancel($event, state.portfolioBaseQuarterItems, state.type.baseQuarter)"
                    @row-dblclick="eventHandler.onRowDoubleClick">

                    <template #empty>
                        데이터가 없습니다.
                    </template>

                    <Column field="Check" header="선택" :sortable="true" style="text-align:center; width:50px;" headerClass="tc">
                        <template #body = {data}>
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.IsChecked" :binary="true" @change="eventHandler.onChange(data, 'Check')"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                    <Column field="BaseYear" header="기준년도" :sortable="true" style="width:40px;" class="tc">
                        <template  #editor="{ data, field }">
                            <input class="input-text mr10" v-model="data[ field ]"  />
                        </template>
                    </Column>
                    <Column field="QuarterName" header="분기" :sortable="true" style="width:300px;" headerClass="tc">
                        <template  #editor="{ data }">
                            <div class="select-box">
                               <select v-model="data.QuarterCode">
                                    <option v-for="item in state.selectBox.quarter" v-bind:value="item.CodeValue" v-bind:key="item" v-text="item.CodeName" />
                               </select>
                            </div>
                        </template>
                    </Column>
                    <Column field="UnitContent" header="단위 및 기준" :sortable="true" style="width:250px;" headerClass="tc">
                        <template  #editor="{ data, field }">
                            <input class="input-text mr10" v-model="data[ field ]"  />
                        </template>
                    </Column>
                    <Column field="DspYN" header="게시여부" :sortable="true" style="text-align:center; width:50px;" headerClass="tc">
                        <template #body = {data}>
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.DspYN" :binary="true" @change="eventHandler.onChange(data)" :disabled="!data.DspCheck"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                    <Column :rowEditor="true" style="width:100px;"></Column>
                </DataTable>
            </div>
            <div class="col-5">
                <p class="s-txt2">수주잔고</p>
                <!-- 수주잔고 -->
                <DataTable class="p-datatable-sm"
                    scrollHeight="497px"
                    :value="state.portfolioOrdersItems"
                    :resizableColumns="true"
                    editMode="row"
                    :rowHover="true"
                    v-model:editingRows="state.portfolioOrdersRowDataModel"
                    @row-edit-init="eventHandler.onRowEditInit($event , state.type.orders)"
                    @row-edit-save="eventHandler.onRowEditSave($event, state.portfolioOrdersItems, state.portfolioOrdersRowDataModel, state.type.orders)"
                    @row-edit-cancel="eventHandler.onRowEditCancel($event, state.portfolioOrdersItems)">

                    <template #empty>
                        데이터가 없습니다.
                    </template>

                    <Column field="BizTypeName" header="구분" :sortable="true" style="width:300px;" headerClass="tc" />
                    <Column field="BizResult" header="수주잔고" :sortable="true" style="text-align:right;width:230px;" headerClass="tc">
                        <template  #editor="{ data, field }">
                            <input class="input-text mr10" v-model="data[ field ]" @input="eventHandler.bizResultInputEvent($event, state.type.orders)" maxlength="22"/>
                        </template>
                    </Column>
                    <Column field="BizPercent" header="비중" :sortable="true" style="text-align:right;width:100px;" headerClass="tc">
                        <template  #editor="{ data, field }">
                            <input class="input-text mr10" v-model="data[ field ]" @input="eventHandler.bizPercentInputEvent($event)" maxlength="4"/>
                        </template>
                    </Column>

                    <Column :rowEditor="true" style="width:100px;"></Column>
                    <template #footer>
                        <p style="text-align:center;">
                            합계 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            {{state.bizResultTotal ?  state.bizResultTotal.toLocaleString() : '-'}}
                        </p>
                    </template>
                </DataTable>

                <!-- <div class="flex-wrap space-between align-center mt30 mb10">
                    <div>
                        <p class="s-txt2">리츠 운영 자산</p>
                    </div>
                    <div>
                        <div class="btn-wrap">
                            <button type="button" class="btn-box green" @click="eventHandler.portfolioRowAdd(state.portfolioREITsItems, state.type.REITs)">추가</button>
                            <button type="button" class="btn-box red" @click="eventHandler.portfolioRowRemove(state.portfolioREITsItems, state.type.REITs)">삭제</button>
                        </div>
                    </div>
                </div> -->
                <!-- 리츠 운영 자산 -->
                <!-- <DataTable class="p-datatable-sm"
                    scrollHeight="497px"
                    :value="state.portfolioREITsItems"
                    :resizableColumns="true"
                    editMode="row"
                    :rowHover="true"
                    v-model:editingRows="state.portfolioREITsRowDataModel"
                    @row-edit-init="eventHandler.onRowEditInit($event, state.type.REITs)"
                    @row-edit-save="eventHandler.onRowEditSave($event, state.portfolioREITsItems, state.portfolioREITsRowDataModel, state.type.REITs)"
                    @row-edit-cancel="eventHandler.onRowEditCancel($event, state.portfolioREITsItems, state.type.REITs)">

                    <template #empty>
                        데이터가 없습니다.
                    </template>

                    <Column field="Check" header="선택" :sortable="true" style="text-align:center; width:50px;" headerClass="tc">
                        <template #body = {data}>
                            <div class="chk-box">
                                <input type="checkbox" v-model="data.IsChecked" :binary="true" @change="eventHandler.onChange(data , 'Check')"/>
                                <label></label>
                            </div>
                        </template>
                    </Column>
                    <Column field="BizTypeName" header="사업구분" :sortable="true" style="width:300px;" headerClass="tc">
                        <template  #editor="{ data }">
                            <div class="select-box">
                               <select v-model="data.BizTypeCode">
                                    <option v-for="item in state.selectBox.division" v-bind:value="item.CodeValue" v-bind:key="item" v-text="item.CodeName" />
                               </select>
                            </div>
                        </template>
                    </Column>
                    <Column field="BizResult" header="사업비율" :sortable="true" style="width:250px;" headerClass="tc">
                        <template  #editor="{ data, field }">
                            <input class="input-text mr10" v-model="data[ field ]"  @input="eventHandler.bizResultInputEvent($event, state.type.REITs)"  maxlength="3" />
                        </template>
                    </Column>
                    <Column :rowEditor="true" style="width:100px;"></Column>
                </DataTable> -->
            </div>
        </div>
    </div>
</template>
<script>
import { ref, reactive } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core';
import { portfolio } from '@/modules/site/PCM_SM0901E.js';
import { common } from '@/modules/common/common.js';
import { useStore } from 'vuex';
import alertManager from '@/utils/alert-confirm-manager';

export default {
    setup() {

        // 스토어
        const store = useStore();

        // state
        const state = reactive({

            // 사업실적 바인딩
            portfolioBusinessItems: null,
        
            // 사업실적 상세 바인딩
            portfolioBusinessRowDataModel: null,

            // 기준분기 바인딩
            portfolioBaseQuarterItems: null,

            // 기준분기 상세 바인딩
            portfolioBaseQuarterRowDataModel: null,

            // 수주잔고 바인딩
            portfolioOrdersItems: null,

            // 수주잔고 상세 바인딩
            portfolioOrdersRowDataModel: null,

            // 리츠 운영 자산 바인딩
            portfolioREITsItems: null,

            // 리츠 운영 자산 상세 바인딩
            portfolioREITsRowDataModel: null,

            // 사업실적: 0 , 수주잔고 및 리츠 운영 자산: 1
            tabIndex: 0,
            
            //선택한 기준분기 시퀀스 값
            seq: null,

            //선택한 기준분기 게시여부 체크확인
            DspYN: null,

            // 수주잔고 합계 값
            bizResultTotal: 0,

            // 사업비율 합계 값
            REITsbizResultTotal: 0,
            
            // 포토폴리오 기준분기 게시여부 체크 바인딩
            DspCheck: false,

            selectBox: {

                // 분기
                quarter: new Array(),

                // 사업 구분
                division: new Array(),
            },

            type: { 
                // 사업실적
                business : 'Business',
                // 기준분기
                baseQuarter : 'BaseQuarter',
                // 수주잔고
                orders : 'Orders',
                // 리츠 운영 자산
                REITs : 'REITs'
            }
        });

        // 메서드
        const methods = {

            /**
             * 포토폴리오 사업실적 목록 가져오기
             */
            getBusinessPortfolioItems: () => {

                portfolio.getBusinessPortfolioItems().then(portfolioBusinessItems => {
                    if (portfolioBusinessItems) {
                        portfolioBusinessItems.forEach(row => {
                            
                            // 조회시 표시여부 Y N 에서 true false 으로 변경
                            row.DspYN = row.DspYN == 'Y' ? true : false;
                        });

                        state.portfolioBusinessItems = portfolioBusinessItems;
                    } 
                })
            },

            /**
             * 포토폴리오 사업실적 표시여부 카운트 체크 메소드 (max 4개)
             */
            businessPortfolioDspYNCountCheck: (portfolioInfo, rowPortfolioInfo) => {

                // 표시여부 카운트 체크 max 4개
                let dspYNCount = 0;

                portfolioInfo.forEach(row => {

                    // 기존 데이터 표시여부 카운트 체크 max 4개
                    if (row.DspYN === true) dspYNCount += 1; 

                    // 수정한 행 표시여부 변경여부 체크후 true 일경우  표시여부 카운트 체크 +1 max 4개
                    if (row.Seq === rowPortfolioInfo.Seq && row.DspYN !== rowPortfolioInfo.DspYN && rowPortfolioInfo.DspYN) dspYNCount += 1;
                });

                // 표시여부 카운트 체크 max 4개
                if(dspYNCount > 4) {

                    alertManager.alert.warning('표시 여부는 4개까지만 설정 가능합니다.')
                    return false;
                };
                return true;
            },

             /**
             * 사업포트폴리오 기준분기 목록 조회 및 수주잔고 및 리츠 운영 자산 상세 조회
             * @param {String} seq 시퀀스
             */
            getBusinessPortfolioAssets: (seq) => {

                portfolio.getBusinessPortfolioAssets(seq).then(async result => {

                    // 기준분기 목록
                    if(seq === '0') {
                        result.Table.rows.forEach(row => {
                            
                            // 게시여부 체크 비활성화
                            row.DspCheck = false;
                            // 조회시 게시여부 Y N 에서 true false 으로 변경
                            row.DspYN = row.DspYN == '1' ? true : false;
                        });
                        state.portfolioBaseQuarterItems = result.Table.rows
                    }
                    
                    // 기준분기별 수주잔고 및 리츠 운영 자산 상세
                    else {
                        
                        // 수주잔고 합계 초기화
                        state.bizResultTotal = 0;

                        // 사업비율 합계 초기화
                        state.REITsbizResultTotal = 0;

                        // 선택한 기준분기 시퀀스값 바인딩           
                        state.seq = seq;

                        result.Table.rows.forEach(rowData => {

                            if (rowData.BizResult) {

                                // 수주잔고 합계구하기
                                state.bizResultTotal += rowData.BizResult;
                            }
                        });
                        // 수주잔고
                        state.portfolioOrdersItems = result.Table.rows;

                        // 수주잔고 - 수주잔고 and 비중 값 구하기
                        state.portfolioOrdersItems.forEach(row => {
                            
                            if (row.BizResult) {
                                //row.Percent = (row.BizResult/state.bizResultTotal * 100).toFixed(1);
                                row.BizResult = row.BizResult.toLocaleString();
                            }
                        });

                        // 리츠 운영 자산
                        //state.portfolioREITsItems = result.Table1.rows

                        // 리츠 운영 자산 사업비율 합계 and 텍스트 가공
                        // state.portfolioREITsItems.forEach(row => {
                        //     if (row.BizResult || row.BizResult == '0') {
                        //         state.REITsbizResultTotal += row.BizResult;
                        //         row.BizResult += '%'; //ToDo. % mask 여부에따라 수정필요
                        //     }
                        // });

                        // 해당 게시분기 게시여부가 체크여부 바인딩
                        state.portfolioBaseQuarterItems.forEach(row => {
                            if (row.Seq == seq) state.DspYN = row.DspYN;
                        })
                        

                    }
                })
            },
            /**
             * 포토폴리오 기준분기 게시여부 체크시 확인 메소드
             */
            baseQuarterPortfolioDspYNCheck: () => {

                if (state.bizResultTotal) {
                // if (state.bizResultTotal && state.portfolioREITsItems.length == 4 && state.REITsbizResultTotal == 100) {
                    return true
                }
                else return false;
            },
            /**
             * 포토폴리오 저장
             * @param {Object} portfolioInfo 
             * @param {string} type Business: 사업실적, BaseQuarter: 기준분기,  Orders: 수주잔고 , REITs: 리츠운영자산 
             * @returns 
             */
            savePortfolioItems: (portfolioInfo, type) => {

                alertManager.confirm.save().then(() => { 

                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);

                    portfolio.savePortfolioItems(portfolioInfo, type)

                    .then((response) => {

                        if(response.data.success) {
                        
                            alertManager.toast.add();

                            // 사업실적 목록 재조회
                            if(type === state.type.business) {
                                methods.getBusinessPortfolioItems();
                                // 탭설정
                                state.tabIndex = 0;
                            }
                            else {
                                // 사업포트폴리오 기준분기 목록 재조회
                                methods.getBusinessPortfolioAssets('0');
                                // 사업포트폴리오 수주잔고 및 리츠 운영 자산 상세 재조회
                                if(response.data.result.value) methods.getBusinessPortfolioAssets(response.data.result.value);
                                // 탭설정
                                state.tabIndex = 1;
                            }
                            
                        }
                    })

                    .finally(() =>{

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);

                    });
                });                
            },

            /**
             * 포토폴리오 수정
             * @param {Object} portfolioInfo 
             * @param {string} type Business: 사업실적, BaseQuarter: 기준분기,  Orders: 수주잔고 , REITs: 리츠운영자산
             * @returns 
             */
            updatePortfolioItems: (portfolioInfo, type) => {

                alertManager.confirm.edit().then(() => { 
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);
                    
                    portfolio.updatePortfolioItems(portfolioInfo, type)
                    
                    .then((response) => {
                    
                        if(response.data.success) {
                        
                            alertManager.toast.save();
                        
                           // 사업실적 목록 재조회
                           if(type === state.type.business) {
                                methods.getBusinessPortfolioItems();
                                // 탭설정
                                state.tabIndex = 0;
                            }
                            else {
                                // 사업포트폴리오 기준분기 목록 재조회
                                methods.getBusinessPortfolioAssets('0');
                                // 사업포트폴리오 수주잔고 및 리츠 운영 자산 상세 재조회
                                if(response.data.result.value) methods.getBusinessPortfolioAssets(response.data.result.value);
                                // 탭설정
                                state.tabIndex = 1;
                            }
                        }
                    })
                
                    .finally(() =>{
                    
                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    
                    });
                });
            },

            /**
             * 포토폴리오 삭제
             * @param {Object} portfolioInfo 
             * @param {string} type Business: 사업실적, BaseQuarter: 기준분기,  Orders: 수주잔고 , REITs: 리츠운영자산
             * @returns 
             */
            deletePortfolioItems: (portfolioInfo, type) => {

                alertManager.confirm.remove().then(() =>{
                    
                    // 스토어 값 변경
                    store.commit("setLoadingStatus", true);

                    portfolio.deletePortfolioItems(portfolioInfo, type).then((response) => {

                        if(response.data.success) {
                        
                            alertManager.toast.remove();
                            
                            // 사업실적 목록 재조회
                           if(type === state.type.business) {
                                methods.getBusinessPortfolioItems();
                                // 탭설정
                                state.tabIndex = 0;
                            }
                            else {

                                // 사업포트폴리오 기준분기 목록 재조회
                                methods.getBusinessPortfolioAssets('0');
                                // 사업포트폴리오 수주잔고 및 리츠 운영 자산 상세 재조회
                                if(response.data.result.value) methods.getBusinessPortfolioAssets(response.data.result.value);
                                // 탭설정
                                state.tabIndex = 1;
                            }
                        }
                    })

                    .finally(() =>{

                        // 스토어 값 변경
                        store.commit("setLoadingStatus", false);
                    });
                });
            },

            // 포토폴리오 사업실적 유효성검사
            businessPortfolioValidation: (rowPortfolioInfo, type) => {

                let validator = rowPortfolioInfo;

                // 순번
                if (!validator.SortOrder) {

                    alertManager.alert.warning('순번을 입력해주세요.');
                }
                //사업실적내용
                else if (!validator.BizContent) {

                    alertManager.alert.warning('사업실적내용을 입력해주세요.');
                }
                // 사업실적
                else if (!validator.BizResult) {

                    alertManager.alert.warning('사업실적을 입력해주세요.');
                }
                else {

                    // 저장시 표시여부 true false 에서 Y N 으로 변경
                    rowPortfolioInfo.DspYN = rowPortfolioInfo.DspYN == true ? 'Y' : 'N';
                    
                    // 신규 여부 판별 - 신규 저장, 있는 경우 수정 
                    if (rowPortfolioInfo.isNew) {
                        
                        methods.savePortfolioItems(rowPortfolioInfo, type);
                    } else {
                    
                        methods.updatePortfolioItems(rowPortfolioInfo, type);
                    }
                }
            },
            
            // 포토폴리오 기준분기 유효성검사
            baseQuartPortfolioValidation: (rowPortfolioInfo, type) => {

                let validator = rowPortfolioInfo;

                // 기준분기 년도, 분기별 중복 여부 반환
                const isValidation = state.portfolioBaseQuarterItems.some(item => `${item.BaseYear}${item.QuarterCode}` === `${rowPortfolioInfo.BaseYear}${rowPortfolioInfo.QuarterCode}` && rowPortfolioInfo.isNew)
                
                // 기준년도
                if (!validator.BaseYear) {
                    
                    alertManager.alert.warning('기준년도를 입력해주세요.');
                }
                // 분기
                else if (!validator.QuarterCode) {

                    alertManager.alert.warning('분기를 입력해주세요.');
                }
                // 년도별 분기 중복검사
                else if (isValidation) {

                    alertManager.alert.warning('해당년도에 동일한 분기가 있습니다.');
                }     
                else {

                    // 저장시 표시여부 true false 에서 Y N 으로 변경
                    rowPortfolioInfo.DspYN = rowPortfolioInfo.DspYN == true ? '1' : '0';
                    
                    // 신규 여부 판별 - 신규 저장, 있는 경우 수정 
                    if (rowPortfolioInfo.isNew) {
                        
                        methods.savePortfolioItems(rowPortfolioInfo, type);
                    } else {
                    
                        methods.updatePortfolioItems(rowPortfolioInfo, type);
                    }
                }
            },

            // 포토폴리오 수주잔고 유효성검사
            ordersPortfolioValidation: (rowPortfolioInfo, type) => {

                // 수주잔고 합 바인딩
                let total = 0;

                // 기준분기 게시여부가 체크된 경우 변경전 객체 바인딩
                let ordersItems = null;

                let validator = rowPortfolioInfo;

                // 기준분기 게시여부가 체크된 경우 실행
                if (state.DspYN) {
                    // 기준분기 게시여부가 체크로 저장된 경우 수주잔고 토탈값 확인
                    state.portfolioOrdersItems.forEach(row=>{
                        if (row.Seq === rowPortfolioInfo.Seq && row.BizTypeCode == rowPortfolioInfo.BizTypeCode && row.BizResult != rowPortfolioInfo.BizResult) {
                            // 기준분기 게시여부가 체크 변경전로 저장된 경우 객체 바인딩
                            ordersItems = Object.assign({}, row);;
                            row.BizResult = rowPortfolioInfo.BizResult;
                        }
                        total +=  Number(row.BizResult ? row.BizResult.replaceAll(',','') : '0');
                    })
                }

                // 수주잔고
                if (!validator.BizResult) {

                    alertManager.alert.warning('수주잔고를 입력해주세요.');
                }
                else if (state.DspYN && !total){
                    // 해당 유효성 검사 실패시 저장시 변경된 데이터 원복
                    state.portfolioOrdersItems.forEach(row => { 
                        if (row.BizTypeCode === ordersItems.BizTypeCode) {
                           
                            row.BizResult = Number(ordersItems.BizResult.replaceAll(',','')).toLocaleString();
                        }
                    })

                    alertManager.alert.warning('해당 게시분기 게시여부가 체크된 경우 비중을 100% 로 맞춰주세요.');
                }
                else {

                    // 수주잔고 , 제거 후 문자열에서 숫자로 변환
                    rowPortfolioInfo.BizResult = Number(rowPortfolioInfo.BizResult.replaceAll(',',''));
                    
                    // 신규 여부 판별 - 신규 저장, 있는 경우 수정 
                    if (rowPortfolioInfo.isNew) {
                        
                        methods.savePortfolioItems(rowPortfolioInfo, type);
                    } else {
                    
                        methods.updatePortfolioItems(rowPortfolioInfo, type);
                    }
                }
            },

            // 포토폴리오 리츠 운영 자산 유효성검사
            REITsPortfolioValidation: (rowPortfolioInfo, type) => {
                
                // 사업비율 합 방인딩
                let total = 0;

                // 기준분기 게시여부가 체크된 경우 변경전 객체 바인딩
                let REITsItem = null;
               
                let validator = rowPortfolioInfo;

                // 기준분기 게시여부가 체크된 경우 실행
                if (state.DspYN) {
                    // 기준분기 게시여부가 체크로 저장된 경우 리츠 운영 자산 저장시 사업비율 토탈값 확인
                    state.portfolioREITsItems.forEach(row => { 
                        if (row.Seq === rowPortfolioInfo.Seq && row.BizTypeCode === rowPortfolioInfo.BizTypeCode && row.BizResult != rowPortfolioInfo.BizResult) {
                            // 기준분기 게시여부가 체크 변경전 객체 바인딩
                            REITsItem = Object.assign({}, row);
                            // 기준분기 게시여부가 체크  
                            row.BizResult = rowPortfolioInfo.BizResult ? rowPortfolioInfo.BizResult + '%' : '0';//ToDo. % mask 여부에따라 수정필요
                        }   
                        total += Number(row.BizResult ? row.BizResult.replace('%','') : '0');//ToDo. % mask 여부에따라 수정필요
                    })
                }

                // 사업구분
                if (!validator.BizTypeCode) {
                    
                    alertManager.alert.warning('사업구분을 선택해주세요.');
                }
                // 사업비율
                else if (!validator.BizResult) {

                    alertManager.alert.warning('사업비율을 입력해주세요.');
                }
                // 해당 게시분기 게시여부가 체크로 저장된 경우 사업비율 100% 체크
                else if (total != 100 && state.DspYN) {

                    // 해당 유효성 검사 실패시 저장시 변경된 데이터 원복
                    state.portfolioREITsItems.forEach(row => { 
                        if (row.BizTypeCode === REITsItem.BizTypeCode) {
                           
                            row.BizResult = REITsItem.BizResult.replace('%','') + '%';//ToDo. % mask 여부에따라 수정필요
                        }
                    })

                    alertManager.alert.warning('해당 게시분기 게시여부가 체크된 경우 사업비율을 100% 로 맞춰주세요.');
                }
                else {

                    // 세션타입 설정
                    rowPortfolioInfo.SectionType = '1';
                    
                    // 시퀀스 추가
                    rowPortfolioInfo.Seq = state.seq;

                    // 사업비율 % 제거 
                    rowPortfolioInfo.BizResult = rowPortfolioInfo.BizResult.replace('%','');//ToDo. % mask 여부에따라 수정필요
                    
                    // 신규 여부 판별 - 신규 저장, 있는 경우 수정 
                    if (rowPortfolioInfo.isNew) {
                        
                        methods.savePortfolioItems(rowPortfolioInfo, type);
                    } else {
                    
                        methods.updatePortfolioItems(rowPortfolioInfo, type);
                    }
                }
            },
        };

        // 이벤트 핸들러
        const eventHandler = {

            // 행 수정 모드 이벤트 처리
            onRowEditInit: (event, type = null) => {

                // 행 수정 모드 설정
                event.data.isEdit = true;

                // 사업 실적
                if (type === state.type.business) { 

                    state.portfolioBusinessRowDataModel = new Array(event.data);
                }
                // 기준 분기 
                else if(type === state.type.baseQuarter) {

                    state.portfolioBaseQuarterRowDataModel = new Array(event.data);

                    state.portfolioBaseQuarterItems.forEach(row => {row.DspCheck = false})

                    // 게시여부 체크박스 활성화
                    event.data.DspCheck = true;

                    // 수정모드일 경우 해당 수주잔고 리츠운영 자산 조회
                    event.data.Seq && methods.getBusinessPortfolioAssets(`${event.data.Seq}`);
                }
                // 수주 잔고
                else if (type === state.type.orders) { 

                    state.portfolioOrdersRowDataModel = new Array(event.data);
                }
                // 리츠 운영 자산
                else if (type === state.type.REITs) { 

                    state.portfolioREITsRowDataModel = new Array(event.data);

                    if(event.data.BizResult) event.data.BizResult = event.data.BizResult.replace('%', '');//ToDo. % mask 여부에따라 수정필요
                }
            },

            // 행 수정 이벤트 처리
            onRowEditSave: (event, portfolioInfo, rowPortfolioInfo, type) => {

                // 게시여부 체크박스 비활성화
                event.data.DspCheck = false;

                portfolioInfo.forEach(row => {
                    // 수정 여부 초기화
                    if (row.isEdit === true) row.isEdit = false;
                });

                // 사업실적 
                if(type === state.type.business)  {

                    //표시여부 체크
                    if(!methods.businessPortfolioDspYNCountCheck(portfolioInfo, event.newData)) return
                    
                    //유효성 검사 후 저장 OR 수정 체크
                    methods.businessPortfolioValidation(event.newData, type);
                }
                // 기준분기 
                else if(type === state.type.baseQuarter)  {

                    //유효성 검사 후 저장 OR 수정 체크
                    methods.baseQuartPortfolioValidation(event.newData, type);
                }
                // 수주잔고
                else if(type === state.type.orders)  {

                    event.newData.BizResult = event.newData.BizResult &&  event.newData.BizResult != '' ? event.newData.BizResult : '0';
                    //유효성 검사 후 저장 OR 수정 체크
                    methods.ordersPortfolioValidation(event.newData, type);
                }
                // 리츠 운영 자산
                else  {
                    
                    event.newData.BizResult = event.newData.BizResult &&  event.newData.BizResult != '' ? event.newData.BizResult : '0';
                
                    //유효성 검사 후 저장 OR 수정 체크
                    methods.REITsPortfolioValidation(event.newData, type);
                }
            },
            // 행 수정 취소 이벤트 처리
            onRowEditCancel: (event, portfolioInfo, type = null) => {
                
                // 기준분기 - 게시여부 체크박스 비활성화
                if (type === state.type.baseQuarter) event.data.DspCheck = false;
                
                else if (type === state.type.REITs) { 
                    event.data.BizResult = event.data.BizResult ? event.data.BizResult + '%' : '0%'; //ToDo. % mask 여부에따라 수정필요
                }
                // 수정 여부 초기화
                portfolioInfo.forEach(row => { if (row.isEdit === true) row.isEdit = false });
            },

            // 행 추가 이벤트 처리
            portfolioRowAdd: (portfolioInfo, type = null) => {
                
                // 기준분기 선택 안한 경우 리츠 운영 자산 추가 버튼 이벤트 막기 
                if(type ===state.type.REITs && !state.seq) return

                // 이전 신규 행을 작업중이거나 기존행 수정중일 경우 처리
                for (let row = 0; row < portfolioInfo.length; row++) {

                    const rows = portfolioInfo[row];

                    if (rows.isNew || rows.isEdit) {

                        // alert 명칭 수정
                        alertManager.alert.warning('이전에 추가한 신규 또는 기존 수정중인 행이 있습니다.')
                        return;
                    }
                }

                // 신규 행 추가
                portfolioInfo.push({isNew: true});
            },

            // 행 삭제 이벤트 처리
            portfolioRowRemove: (portfolioInfo, type = null) => {

                 // 기준분기 선택 안한 경우 리츠 운영 자산 삭제 이벤트 막기
                if(type ===state.type.REITs && !state.seq) return

                // 리스트중 체크한 값이 있는지 판별
                let isChecked = false;

                // 체크한 값이 신규인경우 판별
                let isNewChecked = false;

                portfolioInfo.forEach((row)=>{
                    // 리스트중 체크한 값이 있는지 판별
                    if (row.IsChecked) isChecked = true;
                    // 체크한 값이 신규인경우 판별
                    if (row.IsChecked && row.isNew) isNewChecked = true;
                })

                // 체크여부 확인 
                if(!isChecked)   return;

                // 삭제될 포토폴리오리스트
                let portfolioInfoList = new Array();

                // 신규 행 삭제 처리
                isNewChecked && portfolioInfo.pop();

                // 기존 행 삭제 데이터 가공 // 체크 된 데이터
                portfolioInfo.forEach(row => {
                    
                    if(row.IsChecked === true) portfolioInfoList.push(row)
                });

                // 삭제 할 데이터 존재시 삭제 처리
                if(portfolioInfoList.length > 0) {

                    methods.deletePortfolioItems(portfolioInfoList, type);
                }
            },

            // 기준분기 데이터 테이블 더블 클릭 이벤트 핸들러
            onRowDoubleClick: event => {

                if(event.data.Seq) {

                    // 기준분기 상세 조회 
                    methods.getBusinessPortfolioAssets(`${event.data.Seq}`);
                }
            },
            
            // 수주잔고 - 수주잔고 , 리츠운영자산 - 사업비율 input이벤트
            bizResultInputEvent: (event, type) => {

                // 입력한 데이터
                const inputData = event.data;

                // 입력데이터가 숫자가 아니면 지움
                if (!/[0-9]/.test(inputData)) event.target.value = event.target.value.replace(inputData, '');
                
                // 숫자 천단위 콤마 찍기
                if (type === state.type.orders) {

                    event.target.value = Number(event.target.value.replaceAll(',','')).toLocaleString();
                }
                else {
                    // 입력시 맨앞자리 0막기 : ToDo. 이후 리츠 운영 자산 사업비율 자리수 확정시 변경 ~
                    event.target.value = event.target.value[0] == '0' && event.target.value.length > 1 ? event.target.value.replace('0','') : event.target.value;
                    
                    event.target.value = !event.target.value && event.target.value === '' ? '0' : event.target.value;
                };
            },
            // 수주잔고 - 수주잔고 비중 input 이벤트
            bizPercentInputEvent: (event) => {

                // 입력한 데이터
                const inputData = event.data;
                
                // 입력데이터가 숫자가 아니면 지움
                if (!/[0-9.]/.test(inputData)) event.target.value = event.target.value.replace(inputData, '');
                //event.target.value = (parseFloat(event.target.value) / 10).toFixed(1);
            },

            // 기준 분기별 선택, 게시여부 설정 이벤트 핸들러
            onChange: (data, type = null) => {
                // 게시여부 에디터 모드에서 변경된 값 유효성 검사 
                if(!type &&!methods.baseQuarterPortfolioDspYNCheck()) {
                    data.DspYN = false;
                    alertManager.alert.warning('해당 분기의 수주잔고  입력여부 또는 리츠 운영 자산 항목이 4개이며 사업비율 합이 100%인지  확인해 주세요.')
                } 
                // 선택 에디터 모드에서 변경된 값 체크 여부 전달
                else {
                    if (type && data.isNew && data.isEdit) {
                        state.portfolioBaseQuarterItems.forEach(row => {
                            if(row.isNew) row.IsChecked = data.IsChecked;
                        })
                    }
                }
            },
            
        };

        // Dom 객체 그려진 이후
        onMounted(() => {

            // 포토폴리오 사업실적 목록 초기조회
            methods.getBusinessPortfolioItems();

            // 분기 코드 초기조회
            common.getSystemCodeInfo('CODEINFO', 'CD026000').then(result => state.selectBox.quarter = result);

            // 리츠 운영 자산 구분
            common.getSystemCodeInfo('CODEINFO', 'CD028000').then(result => state.selectBox.division = result);
            
            //사업포트폴리오 기준분기 목록 초기조회
            methods.getBusinessPortfolioAssets('0')
        });

        return {
            state,
            methods,
            eventHandler,
        }
    },
}
</script>